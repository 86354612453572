


















































import Vue from 'vue';
import { ISettingsDataservice } from '../../../shared/src/dataservices/ISettingsDataservice';
import TextField from '../components/TextField.vue';
import TextArea from '../components/TextArea.vue';
import ImageUpload from '../components/ImageUpload.vue';
import { IUUIDHelperService } from '../../../shared/src/services/IUUIDHelperService';

export default Vue.extend({
  components: {
    TextField,
    TextArea,
    ImageUpload
  },
  inject: {
    settingsDataservice: 'settingsDataservice',
    uuidHelperService: 'uuidHelperService'
  },
  methods: {
      shopnameUpdated(shopName: string): void {
        ((this as any).settingsDataservice as ISettingsDataservice).setShopname(shopName);
      },
      descriptionUpdated(description: string): void {
        ((this as any).settingsDataservice as ISettingsDataservice).setDescription(description);
      },
      headerUpdated(header: string): void {
        ((this as any).settingsDataservice as ISettingsDataservice).setHeader(header);
      },
      subheaderUpdated(subheader: string): void {
        ((this as any).settingsDataservice as ISettingsDataservice).setSubheader(subheader);
      },
      imageUpdated(image: File): void {
        if (!image) {
          throw new Error('Trying to updated undefined image');
        }

        // TODO this.imageLoading = true;
        ((this as any).settingsDataservice as ISettingsDataservice).updateFavicon(image).then(() => {
          // TODO this.imageLoading = false;
        });
      },
      imageDeleted(): void {
        ((this as any).settingsDataservice as ISettingsDataservice).deleteFavicon();
      },
  }
});
